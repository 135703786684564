import React, { useState } from "react";
import { Grid, Col } from "react-flexbox-grid";
import { useTranslation } from "react-i18next";
import {
  TopContainer,
  RowLine,
  SliderContainer,
  GridWrapper,
  ButtonStyle,
  ButtonPagination,
  NoReturn
} from "./style";
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";
import icon from "../../assets/svg/example-photo-icon-service.svg";
import Filter from "../../components/Services/Filter";
import ArrowGoBg from "../../assets/svg/arrow-go-bg.svg";
import SeparateIcon from "../../assets/svg/separate-icon-service.svg"
import FilterByName from "../../components/Services/SearchFilter";
import { useEffect } from "react";
import { ImageSeparate } from "./style";
import arrowLeft from "../../assets/svg/arrow-left-commerce.svg"
import arrowRight from "../../assets/svg/arrow-right-commerce.svg"
import arrowRightAll from "../../assets/svg/arrow-right-all-commerce.svg"
import arrowLeftAll from "../../assets/svg/arrow-left-all-commerce.svg"
import ProductsList from "../../components/Services/Products";


const ITEMS_PER_PAGE = 10;

export default function OurServices()  {
  const { t } = useTranslation();
  const services = [
    {
      id: 1,
      name: "João",
      image: icon,
      price: "R$ 1,00",
      unit_price: 1.0,
      price_test: "*",
      description: "Produto de tecnologia com alto padrão de tecnologia ",
      tags: [
        "Programador",
        "Front",
        "Back",
        "Teste",
        "Teste 2",
      ],
      studyArea: ['lbl_area_newdesenvolviment'],
      studyType: ['lbl_filter_concorrency']
    },
    {
      id: 2,
      name: "Henrique",
      image: icon,
      price: "R$ 1,00",
      unit_price: 1.0,
      price_test: "*",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Adipiscing viverra justo pellentesque eget pulvinar hac neque. Nisl eget cras morbi diam vitae auctor amet non. Facilisis tortor odio ultrices nisi faucibus. In aliquam gravida bibendum netus in sed eu, in.",
      tags: [
        "design",
        "illustrator",
        "freelancer",
      ],
      studyArea: ['lbl_area_operationsandtransport'],
      studyType: ['lbl_filter_tendecy']
    },
    {
      id: 3,
      name: "Alex",
      image: icon,
      price: "R$ 100,00",
      unit_price: 1000.0,
      price_test: "*",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Adipiscing viverra justo pellentesque eget pulvinar hac neque. Nisl eget cras morbi diam vitae auctor amet non. Facilisis tortor odio ultrices nisi faucibus. In aliquam gravida bibendum netus in sed eu, in.",
      tags: [
        "react",
        "vue",
        "front-end",
      ],
      studyArea: ['lbl_area_fisic'],
      studyType: ['lbl_filter_tendecy']
    },
    {
      id: 4,
      name: "Eduardo",
      image: icon,
      price: "R$ 1,00",
      unit_price: 1.0,
      price_test: "*",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Adipiscing viverra justo pellentesque eget pulvinar hac neque. Nisl eget cras morbi diam vitae auctor amet non. Facilisis tortor odio ultrices nisi faucibus. In aliquam gravida bibendum netus in sed eu, in.",
      tags: [
        "content",
        "copywriting",
        "seo",
      ],
      studyArea: ['lbl_area_fisic'],
      studyType: ['lbl_filter_tendecy']
    },
    {
      id: 5,
      name: "Lorem",
      image: icon,
      price: "R$ 1,00",
      unit_price: 1.0,
      price_test: "*",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Adipiscing viverra justo pellentesque eget pulvinar hac neque. Nisl eget cras morbi diam vitae auctor amet non. Facilisis tortor odio ultrices nisi faucibus. In aliquam gravida bibendum netus in sed eu, in.",
      tags: [
        "digital marketing",
        "social media",
        "ads",
      ],
      studyArea: ['lbl_area_quimicandmetalurgic'],
      studyType: ['lbl_filter_typetendecy']
    },
    {
      id: 6,
      name: "Lorem",
      image: icon,
      price: "R$ 1,00",
      unit_price: 1.0,
      price_test: "*",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Adipiscing viverra justo pellentesque eget pulvinar hac neque. Nisl eget cras morbi diam vitae auctor amet non. Facilisis tortor odio ultrices nisi faucibus. In aliquam gravida bibendum netus in sed eu, in.",
      tags: [
        "photography",
        "portrait",
        "events",
      ],
      studyArea: ['lbl_area_enginner'],
      studyType: ['lbl_filter_technique']
    },
    {
      id: 7,
      name: "Lorem",
      image: icon,
      price: "R$ 1,00",
      unit_price: 1.0,
      price_test: "*",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Adipiscing viverra justo pellentesque eget pulvinar hac neque. Nisl eget cras morbi diam vitae auctor amet non. Facilisis tortor odio ultrices nisi faucibus. In aliquam gravida bibendum netus in sed eu, in.",
      tags: [
        "translation",
        "languages",
        "proofreading",
      ],
      studyArea: ['lbl_area_fisic'],
      studyType: ['lbl_filter_technique']
    },
    {
      id: 8,
      name: "Lorem",
      image: icon,
      price: "R$ 1,00",
      unit_price: 1.0,
      price_test: "*",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Adipiscing viverra justo pellentesque eget pulvinar hac neque. Nisl eget cras morbi diam vitae auctor amet non. Facilisis tortor odio ultrices nisi faucibus. In aliquam gravida bibendum netus in sed eu, in.",
      tags: [
        "finance",
        "consulting",
        "budgeting",
      ],
      studyArea: [''],
      studyType: ['']
    },
    {
      id: 9,
      name: "Lorem",
      image: icon,
      price: "R$ 1,00",
      unit_price: 1.0,
      price_test: "*",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Adipiscing viverra justo pellentesque eget pulvinar hac neque. Nisl eget cras morbi diam vitae auctor amet non. Facilisis tortor odio ultrices nisi faucibus. In aliquam gravida bibendum netus in sed eu, in.",
      tags: [
        "coaching",
        "personal development",
        "motivation",
      ],
      studyArea: ['lbl_area_eletric'],
      studyType: ['lbl_filter_search']
    },
    {
      id: 10,
      name: "Lorem",
      image: icon,
      price: "R$ 1,00",
      unit_price: 1.0,
      price_test: "*",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Adipiscing viverra justo pellentesque eget pulvinar hac neque. Nisl eget cras morbi diam vitae auctor amet non. Facilisis tortor odio ultrices nisi faucibus. In aliquam gravida bibendum netus in sed eu, in.",
      tags: [
        "tutoring",
        "mathematics",
        "physics",
      ],
      studyArea: ['lbl_area_texteisandpaper'],
      studyType: ['lbl_filter_search']
    }
];
  

const [currentSlide, setCurrentSlide] = useState(0);
const totalPages = Math.ceil(services.length / ITEMS_PER_PAGE);
const [loaded, setLoaded] = useState(true);
const [isSearching, setIsSearching] = useState(false);
const [filteredServices, setFilteredServices] = useState(services)
const [currentProducts, setCurrentProducts] = useState([]);


  const [sliderRef, instanceRef] = useKeenSlider({
    slides: {
      perView: 3,
      spacing: 12,
    },
    breakpoints: {
      "(max-width: 768px)": {
        slides: {
          perView: 2,
          spacing: 12,
        },
      },
      "(max-width: 640px)": {
        slides: {
          perView: 1,
          spacing: 12,
        },
      },
    },
    initial: 0,
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
    created() {
      setLoaded(true);
    },
  });
  
  useEffect(() => {
    const start = currentSlide * ITEMS_PER_PAGE;
    const end = start + ITEMS_PER_PAGE;
    setCurrentProducts(filteredServices.slice(start, end));
  }, [filteredServices, currentSlide]);

  const handleNextPage = () => {
    setCurrentSlide((prevSlide) => Math.min(prevSlide + 1, totalPages - 1));
  };
  
  const handlePreviousPage = () => {
    setCurrentSlide((prevSlide) => Math.max(prevSlide - 1, 0));
  };
  
  const handleFirstPage = () => {
    setCurrentSlide(0);
  };
  
  const handleLastPage = () => {
    setCurrentSlide(totalPages - 1);
  };
  

  const handleFilter = ({ country, name, minPrice, maxPrice, studyArea, studyType }) => {
    setFilteredServices(services);
    setIsSearching(true);
    
    if (!country && !name && !minPrice && !maxPrice && (!studyArea || studyArea.length === 0) && (!studyType || studyType.length === 0)) {
      setCurrentSlide(0);
      return;
    }
  
    let filtered = services;
  
    if (country) {
      filtered = filtered.filter(service => service.country.toLowerCase().includes(country.toLowerCase()));
    }
  
    if (name) {
      filtered = filtered.filter(service => service.name.toLowerCase().includes(name.toLowerCase()));
    }
  
    if (minPrice) {
      filtered = filtered.filter(service => parseFloat(service.unit_price) >= parseFloat(minPrice));
    }
  
    if (maxPrice) {
      filtered = filtered.filter(service => parseFloat(service.unit_price) <= parseFloat(maxPrice));
    }
  
    if (studyArea && studyArea.length > 0) {
      filtered = filtered.filter(service => service.studyArea.some(area => studyArea.includes(area)));
    }
  
    if (studyType && studyType.length > 0) {
      filtered = filtered.filter(service => service.studyType.some(type => studyType.includes(type)));
    }
  
    setFilteredServices(filtered);
    
    setCurrentSlide(0);
  };

  return (
    <div>

      <TopContainer fluid>
        <div
          style={{
            background: `#05036F url(${ArrowGoBg})`,
            paddingTop: "30px",
            paddingBottom: "40px",
            backgroundClip: "cover",
            backgroundRepeat: "no-repeat",
          }}
        >
          <Grid>
            <Col style={{ paddingBottom: "30px" }}>
              <h3>{t("lbl_servicos")}</h3>
              <RowLine />
            </Col>
            <Col>
              <p>
               Adquira serviços de nossos parceiros.
              </p>
            </Col>
          </Grid>
        </div>
      </TopContainer>
      <GridWrapper>
      <FilterByName />
      <ImageSeparate src={SeparateIcon} alt="Separate Icon" />
        <SliderContainer>
          <Filter onFilter={handleFilter} style={{ display: isSearching && filteredServices.length === 0 ? 'none' : 'block' }} />
          {filteredServices.length === 0 && isSearching && (
            <NoReturn>{t("lbl_not_found")}</NoReturn>
          )}
          <ProductsList products={currentProducts} />
        </SliderContainer>
        {loaded && (
        <ButtonPagination>
          <ButtonStyle onClick={handleFirstPage} disabled={currentSlide === 0 || filteredServices.length === 0}>
            <img src={arrowLeftAll} alt="Primeira" />
          </ButtonStyle>
          <ButtonStyle
            disabled={currentSlide === 0 || filteredServices.length === 0}
            onClick={handlePreviousPage}
          >
            <img src={arrowLeft} alt="Anterior" />
          </ButtonStyle>
          <ButtonStyle
            onClick={handleNextPage}
            disabled={currentSlide >= Math.ceil(filteredServices.length / ITEMS_PER_PAGE) - 1 || filteredServices.length === 0}
          >
            <img src={arrowRight} alt="Próxima" />
          </ButtonStyle>
            <ButtonStyle onClick={handleLastPage} disabled={currentSlide >= Math.ceil(filteredServices.length / ITEMS_PER_PAGE) - 1 || filteredServices.length === 0}>
            <img src={arrowRightAll} alt="Última" />
          </ButtonStyle>
        </ButtonPagination>
      )}
      </GridWrapper>
    </div>
  );
};


