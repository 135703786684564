import React, { useRef, useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import Panel from "../../components/Panel";
import { ReactComponent as SearchIcon } from "../../assets/svg/magnifying-icon.svg";
import { Col, Grid, Row } from "react-flexbox-grid";
import { PlanWarning, SearchContainer, RowForm } from "./style";
import KeyWords from "../../components/KeyWords";
import Button from "../../components/Button";
import TechField from "../../components/TechField";
import { Form } from "@unform/web";
import * as yup from "yup";
import ModalContent from "../../components/ModalContent";
import { api321go } from "../../api/api321go";
import Loader from "../../components/LoadScreen";
import { useSearch } from "../../contexts/SearchContext";
import { useAuth } from "../../contexts/AuthContext";

const Search = () => {
  const { t } = useTranslation();
  const formRef = useRef(null);
  const history = useHistory();
  const [introModalIsOpen, setIntroModalIsOpen] = useState(true);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const { setLoadingMap, setSearchTerms, setCountriesResult } = useSearch();
  const { handleUserData } = useAuth();
  const [user, setUser] = useState(null);
  let lang = localStorage.getItem("i18nextLng");
  lang = lang === "pt_BR" ? "pt" : lang;
  const thereIsNoPlan = user && user["status_plan"] && user["status_plan"][lang].length === 0 ? true : false;

  const loaderUser = useCallback(
    async () => {
      const data = await handleUserData();
      setUser(data);
    }, [handleUserData]
  )

  const handleCloseIntroModal = () => {
    setIntroModalIsOpen(!introModalIsOpen);
  };

  const validateFieldsToSubmit = async (data) => {
    try {
      console.log("data: ", data);
      formRef.current.setErrors({});

      const signupSchema = yup.object().shape({
        keyword01: yup.string().required(t("form_error_required_field")),
        keyword02: yup.string().required(t("form_error_required_field")),
        keyword03: yup.string().required(t("form_error_required_field")),
        tech_field: yup.string().required(t("form_error_required_field")),
      });

      await signupSchema.validate(data, {
        abortEarly: false,
      });
      setLoadingSearch(true);
      await handleSearch(data);
    } catch (error) {
      setLoadingSearch(false);

      const validationErrors = {};
      if (error instanceof yup.ValidationError) {
        error.inner.forEach((err) => {
          validationErrors[err.path] = err.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    } finally {
      setLoadingSearch(false);
    }
  };

  const handleSearch = async (searchData) => {
    try {
      const searchParams = {
        ...searchData,
        boolean01: "AND",
        boolean02: "AND"
      }

      const { data } = await api321go.post("/search", searchParams);
      const { available, restricted, unavailable } = data;
      const searchKey = data.query_hash;
      setSearchTerms({ ...data, time: new Date(), searchKey });
      setCountriesResult({ available, restricted, unavailable });

      if (searchKey) {
        history.push(`/search/${searchKey}`);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    loaderUser();
    setLoadingMap(false);
  }, []);

  return (
    <>
      <Loader active={loadingSearch} />
      <SearchContainer>
        <Grid>
          <Row center="lg">
            <Col lg={10}>
              <Panel icon={SearchIcon} header={t("lbl_new_search")}>
                {thereIsNoPlan && (
                  <PlanWarning>
                    {t("lbl_plan_status")} <Link to="/subscription">{t("lbl_active_pan")}</Link>
                  </PlanWarning>)
                }

                <Form ref={formRef} onSubmit={validateFieldsToSubmit}>
                  <Grid style={{ marginTop: "1em" }}>
                    <RowForm>
                      <Col lg>
                        <KeyWords />
                      </Col>
                      <Col lg>
                        <TechField />
                      </Col>
                      <Col lg={3}>
                        <Button
                          label="GO"
                          width="100%"
                          type="submit"
                          disabled={thereIsNoPlan}
                        />
                      </Col>
                    </RowForm>
                  </Grid>
                </Form>
              </Panel>
            </Col>
          </Row>
        </Grid>
        <ModalContent
          isOpen={introModalIsOpen}
          header="lbl_how_to_search_title"
          content="lbl_how_to_search_body"
          closeModal={handleCloseIntroModal}
        />
      </SearchContainer>
    </>
  );
};

export default Search;