import React from "react";
import { DefaultButton } from "./style";
import { getIconStroke } from "../../utils/buttonColors";
import { useHistory } from "react-router-dom";

const Button = ({
  icon,
  label,
  disabled,
  type,
  size,
  onClick,
  to,
  width,
  buttonStyle,
  clickAndNavigateMode = false
}) => {
  const Icon = icon ? icon : null;
  const history = useHistory();

  const handleClick = () => {
    if (!clickAndNavigateMode) {
      if (to) {
        return history.push(to);
      } else if (onClick) {
        return onClick();
      } else {
        return null;
      }
    } else {
      history.push(to);
      onClick();
    }
  };

  return (
    <DefaultButton
      buttonStyle={buttonStyle}
      width={width}
      type={type}
      size={size}
      disabled={disabled}
      onClick={handleClick}
    >
      {Icon && (
        <Icon
          width={size === "large" ? "24" : "18"}
          style={{ marginRight: label ? "0.3em" : "0" }}
          stroke={getIconStroke({ disabled, buttonStyle })}
        />
      )}
      {label && <p>{label}</p>}
    </DefaultButton>
  );
};

export default Button;