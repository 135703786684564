import styled from 'styled-components';

export const Section = styled.section`
  margin-bottom: 56px;
`;

export const Header = styled.header`
  background-color: #05036F;
  background-size: auto;
  background-repeat: no-repeat;
  background-position: center;
  height: 40vh;

  @media (max-width: 420px) {
    height: 60vh;
  }
`;

export const Slide = styled.div`
  background-color: #05036F;
  border-radius: 10px;
  display: flex;
  margin-top: -18rem;
  flex-direction: row;
  flex-wrap: wrap;

  @media (max-width: 420px) {
    margin-top: -28.9rem;
  }
`;

export const SlideChamada = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  margin: auto;
  padding-left: 4rem;

  @media (max-width: 420px) {
    padding: 1rem;
    justify-content: center;
  }
`;

export const Title = styled.h1`
  color: #F2B349;
  font-style: bold;
  font-weight: 600;
  line-height: 50px;

  @media (max-width: 420px) {
    font-size: 33px;
    line-height: 50px;
  }
`;

export const Excerpt = styled.p`
  color: #F9F9F9;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  margin: 2rem 0;
`;

export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 32px;
`;

export const Button = styled.a`
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  color: #fff;
  background-color: transparent;
  border: 1px solid #00B6BA;
  text-decoration: none;
  border-radius: 5px;

  &:hover {
    background-color: #00B6BA;
    border-color: #00B6BA;
  }

  @media (max-width: 420px) {
    font-size: 14px;
  }
`;

export const SlideImage = styled.div`
  margin-bottom: 2rem;

  @media (max-width: 768px) {
    margin-top: 2rem;
    margin-bottom: 0;
    width: 100%;
    display: flex;
    justify-content: end;
  }
`;

export const Image = styled.img`
  border-radius: 0 10px 0 0;
`;

export const SeparateIcon = styled.img`
  display: block;
  margin: 20px auto;
`;