import styled from 'styled-components';

export const Section = styled.section`
  background-color: #00B6BA;
  text-align: center;
  padding: 50px 0;
`;

export const Container = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
`;

export const Row = styled.div`
  display: flex;
  justify-content: center;
`;

export const Col = styled.div`
  flex: 1;
`;

export const Title = styled.h2`
  color: #FFFFFF;
  margin-bottom: 1rem;
`;

export const Partners = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 80px;

  @media (max-width: 420px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    gap: 1rem;
  }
`;

export const PartnerImage = styled.img`
  @media (max-width: 420px) {
    width: 100px;
  }
`;
