import React from 'react';
import { Section, Container, Title, Description, Button, Etapas, EtapaCard, CardImage, CardNumber } from './style';
import iconeone from "../../../assets/svg/home/mid-level-infos/Icone-4_1.png";
import iconetwo from "../../../assets/svg/home/mid-level-infos/Icone-4_2.png";
import iconetree from "../../../assets/svg/home/mid-level-infos/Icone-4_3.png";
import iconefour from "../../../assets/svg/home/mid-level-infos/Icone-4_4.png";
import iconefive from "../../../assets/svg/home/mid-level-infos/Icone-4_5.png";
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";

const Plataforma = () => {
  const { t } = useTranslation();
  const etapas = [
    { number: '1', text: t("lbl_step_1"), image: iconeone },
    { number: '2', text: t("lbl_step_2"), image: iconetwo },
    { number: '3', text: t("lbl_step_3"), image: iconetree },
    { number: '4', text: t("lbl_step_4"), image: iconefour },
    { number: '5', text: t("lbl_step_5"), image: iconefive }
  ];

  const btnLink = "#"; // Coloque o link correto aqui
  const history = useHistory();
  return (
    <Section className="plataforma">
      <Container>
        <Title className="cl-azul">{t("lbl_access_platform")}</Title>
        <Description className="descricao p1 cl-azul">
          {t("lbl_access_steps")}
        </Description>
        <Etapas className="etapas">
          {etapas.map((etapa, index) => (
            <EtapaCard key={index}>
              <CardImage src={etapa.image} alt={`Etapa ${etapa.number}`} />
              <CardNumber>{etapa.number}</CardNumber>
              <p>{etapa.text}</p>
            </EtapaCard>
          ))}
        </Etapas>
        <div className="comece">
          <Button href={btnLink} className="btn btn-primary" onClick={() => history.push('/login')}>
            {t("btn_start_now")}
          </Button>
        </div>
      </Container>
    </Section>
  );
};

export default Plataforma;