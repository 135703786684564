import styled from 'styled-components';

export const Section = styled.section`
  margin-bottom: 56px;
  text-align: center;
`;

export const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
`;

export const Title = styled.h3`
  color: #05036F;
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 16px;
`;

export const Description = styled.div`
  color: #003366;
  padding: 16px;
  font-size: 1rem;
  margin-bottom: 32px;
`;

export const Button = styled.a`
  display: inline-block;
  padding: 12px 24px;
  margin-top: 16px;
  background-color: #05036F;
  color: #5EE9EC;
  border-radius: 8px;
  text-decoration: none;
`;

export const Etapas = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  gap: 24px;
  margin-bottom: 56px;
  @media(max-width: 800px){
    flex-wrap: wrap;
  }
`;

export const EtapaCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  background-color: #FFFFFF;
  box-shadow: 4px 4px 4px rgba(5, 3, 111, 0.15);
  border-radius: 16px;
  width: calc(33% - 32px);
  min-width: 200px;
  box-sizing: border-box;

  p {
    font-size: 1rem;
    color: #333;
    margin: 0;
    text-align: center;
  }

  @media (max-width: 768px) {
    width: calc(50% - 32px);
  }

  @media (max-width: 480px) {
    width: 100%;
  }
`;

export const CardImage = styled.img`
  height: 64px;
  margin-bottom: 16px;
`;

export const CardNumber = styled.div`
  font-size: 2rem;
  font-weight: bold;
  color: #003366;
  background: #FFFFFF;
  padding: 8px;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 16px;
`;
