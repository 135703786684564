import React from 'react';
import styled from 'styled-components';
import {
  Section,
  Container,
  Row,
  Col,
  Title,
  Box,
  BoxHeader,
  BoxHeaderImage,
  BoxBody,
} from './style';
import iconeone from "../../../assets/svg/home/mid-level/ICONE-1_1-mid-level.png";
import iconetwo from "../../../assets/svg/home/mid-level/Icone-1_2-mid-level.png";
import iconetree from "../../../assets/svg/home/mid-level/Icone-1_3-mid-level.png";
import iconeonesecond from "../../../assets/svg/home/mid-level/Icone-2_1-mid-level.png";
import iconetwosecond from "../../../assets/svg/home/mid-level/Icone-2_2-mid-level.png";
import iconetreesecond from "../../../assets/svg/home/mid-level/Icone-2_3-mid-level.png";
import iconeonethird from "../../../assets/svg/home/mid-level/Icone-3_1-mid-level.png";
import iconesecondthird from "../../../assets/svg/home/mid-level/Icone-3_2-mid-level.png";
import iconetreethird from "../../../assets/svg/home/mid-level/Icone-3_3-mid-level.png";
import { useTranslation } from "react-i18next";
import Separate from '../../../assets/svg/AboutUs/separate-icon-service.svg';

const Highlight = styled.span`
  color: #00B6BA;
`;

const HighlightSectors = styled.span`
  color: #B9003E;
  display: inline;
  text-align: end;
`;

const HighlightPatents = styled.span`
  color: #F2B349;
`;

const MidLevel = () => {
  const { t } = useTranslation();

  const highlightText = (text, words, HighlightComponent) => {
    const parts = text.split(new RegExp(`(${words.join('|')})`, 'gi'));
    return parts.map((part, index) =>
      words.some(word => word.toLowerCase() === part.toLowerCase()) ? <HighlightComponent key={index}>{part}</HighlightComponent> : part
    );
  };

  const comoFuncionaData = [
    {
      title: t('title_coverage'),
      items: [
        { text: t('text_coverage_1'), image: iconeone },
        { text: t('text_coverage_2'), image: iconetwo },
        { text: t('text_coverage_3'), image: iconetree },
      ],
    },
    {
      title: t('title_sectors'),
      items: [
        { text: t('text_sectors_1'), image: iconeonesecond },
        { text: t('text_sectors_2'), image: iconetwosecond },
        { text: t('text_sectors_3'), image: iconetreesecond },
      ],
    },
    {
      title: t('title_companies'),
      items: [
        { text: t('text_companies_1'), image: iconeonethird },
        { text: t('text_companies_2'), image: iconesecondthird },
        { text: t('text_companies_3'), image: iconetreethird },
      ],
    },
  ];

  return (
    <Section className="como-funciona">
      <Container>
        {comoFuncionaData.map((data, dataIndex) => (
          <React.Fragment key={dataIndex}>
            <Row>
              <Col>
                <Title className="cl-azul">
                  {data.title.toLowerCase().includes('setores econômicos') || data.title.toLowerCase().includes('economic sectors') || data.title.toLowerCase().includes('sectores económicos')
                    ? highlightText(data.title, ['setores econômicos', 'economic sectors', 'sectores económicos'], HighlightSectors)
                    : data.title.toLowerCase().includes('patentes') || data.title.toLowerCase().includes('patents')
                    ? highlightText(data.title, ['patentes', 'patents'], HighlightPatents)
                    : highlightText(data.title, ['cobertura', 'coverage'], Highlight)}
                </Title>
              </Col>
            </Row>
            <Row>
              {data.items.map((item, itemIndex) => (
                <Col key={itemIndex}>
                  <Box>
                    <BoxHeader>
                      <BoxHeaderImage
                        src={item.image}
                        alt={`Imagem ${dataIndex + 1}-${itemIndex + 1}`}
                      />
                    </BoxHeader>
                    <BoxBody className="p2">{item.text}</BoxBody>
                  </Box>
                </Col>
              ))}
            </Row>
          </React.Fragment>
        ))}
      </Container>
      <img src={Separate} alt="Separate Icon" />
    </Section>
  );
};

export default MidLevel;