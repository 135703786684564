import styled from 'styled-components';

export const Section = styled.section`
  margin-bottom: 56px;
`;

export const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
`;

export const Row = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: flex-end;

  @media (max-width: 640px) {
    gap: 1rem;
  }
`;

export const Col = styled.div`
  flex: 1;

  padding: 0 15px;

  @media (max-width: 640px) {
    max-width: 100%;
  }
`;

export const Title = styled.h3`
  margin-bottom: 32px;
  font-weight: 600;
  line-height: 66px;
  font-size: 2.3rem;
  color: #000043;
  @media (max-width: 420px) {
    font-size: 18px;
    line-height: 20px;
  }
`;

export const Box = styled.div`
  border-bottom: 4px solid #00B6BA;
  padding: 15px;
  margin-bottom: 30px;
`;

export const BoxHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const BoxHeaderImage = styled.img`
  max-width: 15%;
  height: auto;
  border-radius: 10px;
`;

export const BoxHeaderTitle = styled.div`
  padding: 1rem;
  color: #00B6BA;
  font-weight: 600;
  font-size: 1.2rem;
  text-align: center;
`;

export const BoxBody = styled.div`
  padding: 1rem;
  color: #333;
  font-size: 1rem;
  text-align: center;
`;
