import React, { useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import Button from "../../Button";
import Line from "../../../assets/svg/line-service.svg";
import icon from "../../../assets/svg/example-photo-commerce.svg";
const ListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-evenly;

  @media (max-width: 1200px) {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 45%;
  border-radius: 2%;
  margin-bottom: 10px;
  margin-top: 10px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.20) 4px 6px 8px;

  @media (max-width: 1200px) {
    width: 80%;
  }

`;

const Container = styled.div`
    display: flex;
    flex-flow: wrap;
    -webkit-box-align: center;
    align-items: stretch;
    gap: 10%;
    flex-direction: column;
  @media (max-width: 768px) {
    gap: 3%;
  }
`;

const Price = styled.div`
  display: flex;

  gap: 20%;
  align-items: flex-end;
  margin-top: 16px;
  justify-content: space-between;
  @media (max-width: 1200px) {
    gap: 80%

  }
  @media (max-width: 550px) {
    gap: 65%

  }
`;

const UlList = styled.ul`
  display: flex;
  list-style: none;
  color: rgb(0, 182, 186);
  font-weight: bold;
  flex-wrap: wrap; 
`;

const PartnerName = styled.h2`
  color: #05036f;
  font-size: 24px;
`;

const LinePrice = styled.div`
  display: flex;
  flex-direction: column;
`;

const PriceLine = styled.div`
  font-weight: bold;
`;

const Value = styled.div`
  font-weight: bold;
  color: #000043;
`;

const BarraVertical = styled.span`
  margin: 0 5px; /* Espaçamento entre as palavras */
`;

const ContainerInfo = styled.div`
//max-width: 50%;
`

const ShowMoreButton = styled.span`
  color: blue;
  cursor: pointer;
`;

const ProductsList = ({ products }) => {
  const { t } = useTranslation();
  const [showFullDescription, setShowFullDescription] = useState(false);

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  return (
    <ListContainer>
      {products.map((partner) => (
        <Card key={partner.id}>
                <img src={partner.image} alt={partner.name} />
          <Container>
      
            <ContainerInfo>
              <PartnerName>{t(`${partner.name}`)}</PartnerName>

              <UlList>
                {partner.tags.slice(0, 4).map((tag, index) => (
                  <React.Fragment key={index}>
                    {index > 0 && <BarraVertical>|</BarraVertical>}
                    <li>{t(tag)}</li>
                  </React.Fragment>
                ))}
                {partner.tags.length > 4 && (
                  <li>+ {partner.tags.length - 4} {t("more")}</li>
                )}
              </UlList>
            </ContainerInfo>
          </Container>

          <p>
            {showFullDescription
              ? partner.description
              : partner.description.slice(0, 280)}
            {partner.description.length > 280 && !showFullDescription && (
              <ShowMoreButton onClick={toggleDescription}> Ver mais</ShowMoreButton>
            )}
          </p>
          <LinePrice>
            <img src={Line} alt={Line} />
            <Price>
              <PriceLine>
                <p>Valor </p>
                <Value>{partner.price}</Value>
              </PriceLine>
              
                   <Button
         
                label={'Entrar em contato'}
                onClick={() => {
                  localStorage.setItem("selectedProduct", JSON.stringify(partner));
                }}
                clickAndNavigateMode={true}
                to="/service/payment"
              />
            
            </Price>
          </LinePrice>
        </Card>
      ))}
    </ListContainer>
  );
};

export default ProductsList;
