import { Col, Grid, Row } from "react-flexbox-grid";
import styled from "styled-components";


export const TopContainer = styled(Grid)`
  color: ${(props) => props.theme.colors.light.default};
  padding: 0;
  margin-bottom: 7em;
  & img {
    width: 7em;
  }
`;

export const BotContainer = styled(Col)`
  position: static;
`;

export const RowLine = styled(Row)`
  background-color: ${(props) => props.theme.colors.yellow.default};
  height: 5px;
  margin-bottom: 50px;
`;

export const ColBlock = styled(Col)`
  width: 100%;
  background-color: white;
  color: black;
  gap: 30px;

`;

export const ColorDots = styled(Row)`
  display: flex;
`;

export const ImgBlock = styled(Col)`
  margin-top: 30px
`;

export const HeadTittle = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
    & h3 {
      font-size: 18px;
      font-weight: 100;
    }
    & h2 {
      font-size: 25px;
      color: #404040;
    }
`;

export const PriceTag = styled(Row)`
    & h2 {
      font-size: 45px;
      color: #482E9F;
    }
`;

export const GridWrapper = styled(Grid)`
  @media (min-width: 1280px) {
    padding-left: 0;
    padding-right: 0;
  }
`;

export const SliderContainer = styled.div`
  display: flex;
  margin-top: -100px;
`;

export const ArrowWrapper = styled.div`
  width: 100%;
  margin: 0.5em 0;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1.5em;
`;

export const HeadContainer = styled(Grid)`
  color: ${(props) => props.theme.colors.light.default};
  padding-top: 50px;
  padding-bottom: 20px;
`;

export const ArrowContainer = styled.button`
  background-color: transparent;
  border: 0;
  width: 20px;
  height: 20px;
  cursor: pointer;

  svg {
    color: ${(props) => props.theme.colors.blue.default};
    transition: color 0.2s;

    &:hover {
      color: ${(props) => props.theme.colors.seaGreen.default};
    }
  }

  &:disabled {
    svg {
      color: ${(props) => props.theme.colors.light.dark};
    }
    
  }
`;
