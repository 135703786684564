import styled from 'styled-components';

export const Section = styled.section`
  margin-bottom: 56px;
`;

export const Container = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
`;

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
`;

export const Col = styled.div`
  flex: 1;
  margin: 0.5rem;
  text-align: left;

  &.image {
    text-align: right;
  }

  @media (max-width: 420px) {
    &.col-7 {
      flex: 0 0 100%;
      max-width: 100%;
    }

    &.col-7 h3 {
      line-height: 30px;
    }

    &.col-7 h3 small {
      font-size: 14px;
      line-height: 1;
    }

    &.col-5.image {
      display: none;
    }
  }
`;

export const Title = styled.h2`
  color: #404040;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
`;

export const TitleSecond = styled.p`
  color: #404040;
  font-weight: 200;
  font-size: 18px;
  line-height: 36px;
`;

export const FAQContent = styled.div`
  margin-bottom: 1.5rem;

  h3 {
    color: #404040;
    font-weight: 600;
    font-size: 16px;
    line-height: 36px;
    cursor: pointer;
  }

  p {
    color: rgb(64, 64, 64);
    font-family: "Hind Vadodara", sans-serif;
    font-weight: 400;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-in-out, padding 0.5s ease-in-out;
  }

  &.active p {
    max-height: 1000px; 
    padding: 1rem 0;
  }

  .faq-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid rgb(5, 3, 111);
  }

  h3 {
    margin: 0;
  }

  img {
    cursor: pointer;
  }
`;

export const FAQImage = styled.div`
  margin: auto;
  text-align: right;
`;

export const Image = styled.img`
  width: 80%;
`;

export const ImageIcon = styled.img`
width: 60%;
`