import { useRef } from "react";
import ReactSelect from "react-select";
import { Tooltip as ReactTooltip } from 'react-tooltip';

import theme from "../../shared/theme";
import { DefaultSelect } from "./style";
import { Flex } from "../../shared/style";
import { ReactComponent as TipIcon } from "../../assets/svg/tip-icon.svg";
import { getLabelColor, getSelectBorderColor, getSelectColor } from "../../utils/selectColors";

export const Select = ({ options, label, tip, ...rest }) => {
  const selectRef = useRef(null);

  const customStyles = {
    option: (styles, state) => {
      return {
        ...styles,
        backgroundColor: state.isSelected && theme.colors.light.dark,
        color: theme.colors.black.default,
        cursor: state.isDisabled ? "not-allowed" : "default",
        textAlign: "start",
        "&:hover": {
          backgroundColor: !state.isSelected && theme.colors.light.default,
        },
        "&:active": {
          backgroundColor: theme.colors.light.dark,
        },
      };
    },
    control: (styles, state) => ({
      ...styles,
      marginTop: "0.25em",
      color: theme.colors.black.default,
      backgroundColor: theme.colors.light.default,
      borderRadius: 4,
      boxShadow: state.isFocused
        ? `inset 0 0 0 2px ${getSelectBorderColor({ state })}`
        : `inset 0 0 0 1px ${getSelectBorderColor({ state })}`,
      border: "none",
      "&:hover": {
        boxShadow: `inset 0 0 0 2px ${getSelectBorderColor({ state })}`,
      },
    }),
    placeholder: (styles) => ({
      ...styles,
      color: theme.colors.light.dark,
      fontSize: "0.8125em",
    }),
    dropdownIndicator: (styles, state) => ({
      ...styles,
      color: state.isDisabled ? theme.colors.light.dark : getSelectColor({ state }),
      "&:hover": {
        color: getSelectColor({ state }),
      },
    }),
    singleValue: (styles) => ({
      ...styles,
      color: theme.colors.blue.default,
    }),
  };

  return (
    <DefaultSelect disabled={rest.isDisabled}>
      <Flex>
        <p>{label}</p>
        {tip && (
          <>
            <TipIcon
              data-tip={tip}
              width="24"
              height="24"
              stroke={getLabelColor({ disabled: rest.isDisabled })}
            />
            <ReactTooltip place="top" effect="solid" />
          </>
        )}
      </Flex>
      <ReactSelect
        components={{
          IndicatorSeparator: () => null,
        }}
        styles={customStyles}
        ref={selectRef}
        classNamePrefix="react-select"
        options={options}
        {...rest}
      />
    </DefaultSelect>
  );
};
