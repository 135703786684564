import React, { useRef, useEffect } from "react";
import ReactSelect from "react-select";
import { useField } from "@unform/core";
import { DefaultSelect } from "./style";
import { Flex } from "../../shared/style";
import theme from "../../shared/theme";
import {
  getLabelColor,
  getSelectBorderColor,
  getSelectColor,
} from "../../utils/selectColors";
import { ReactComponent as TipIcon } from "../../assets/svg/tip-icon.svg";
import { Tooltip as ReactTooltip } from 'react-tooltip';

const Select = ({ name, options, label, tip, ...rest }) => {
  const selectRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  const customStyles = {
    option: (styles, state) => {
      return {
        ...styles,
        backgroundColor: state.isSelected && theme.colors.light.dark,
        color: theme.colors.black.default,
        cursor: state.isDisabled ? "not-allowed" : "default",
        textAlign: "start",
        "&:hover": {
          backgroundColor: !state.isSelected && theme.colors.light.default,
        },
        "&:active": {
          backgroundColor: theme.colors.light.dark,
        },
      };
    },
    control: (styles, state) => ({
      ...styles,
      marginTop: "0.25em",
      color: error ? theme.colors.sweetRed.default : theme.colors.black.default,
      backgroundColor: theme.colors.light.default,
      borderRadius: 4,
      boxShadow: state.isFocused
        ? `inset 0 0 0 2px ${getSelectBorderColor({ state, error })}`
        : `inset 0 0 0 1px ${getSelectBorderColor({ state, error })}`,
      border: "none",
      "&:hover": {
        boxShadow: `inset 0 0 0 2px ${getSelectBorderColor({ state, error })}`,
      },
    }),
    placeholder: (styles) => ({
      ...styles,
      color: theme.colors.light.dark,
      fontSize: "0.8125em",
    }),
    dropdownIndicator: (styles, state) => ({
      ...styles,
      color: state.isDisabled
        ? theme.colors.light.dark
        : getSelectColor({ state, error }),
      "&:hover": {
        color: getSelectColor({ state, error }),
      },
    }),
    singleValue: (styles) => ({
      ...styles,
      color: error ? theme.colors.sweetRed.default : theme.colors.blue.default,
    }),
  };

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      path: "state.value",
      getValue: (ref) => {
        if (rest.isMulti) {
          if (!ref.state.value) {
            return [];
          }
          return ref.state.value.map((option) => option.value);
        } else {
          if (!ref.state.value) {
            return "";
          }
          return ref.state.value.value;
        }
      },
    });
  }, [fieldName, registerField, rest.isMulti]);

  return (
    <DefaultSelect error={error} disabled={rest.isDisabled}>
      <Flex>
        <p>{label}</p>
        {tip && (
          <>
            <TipIcon
              data-tip={tip}
              width="24"
              height="24"
              stroke={getLabelColor({ error, disabled: rest.isDisabled })}
            />
            <ReactTooltip place="top" effect="solid" />
          </>
        )}
      </Flex>
      <ReactSelect
        components={{
          IndicatorSeparator: () => null,
        }}
        defaultValue={
          defaultValue &&
          options.find((option) => option.value === defaultValue)
        }
        styles={customStyles}
        ref={selectRef}
        classNamePrefix="react-select"
        options={options}
        {...rest}
      />

      {error && <small>{error}</small>}
    </DefaultSelect>
  );
};
export default Select;
