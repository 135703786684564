import React from "react";
import { useHistory } from "react-router-dom";
import { Flex } from "../../shared/style";
import Button from "../Button";
import {
  LogicType,
  PaginationContainer,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  ScrollAreaRoot,
  ScrollAreaViewport,
  ScrollAreaScrollbar,
  ScrollAreaThumb,
} from "./style";
import { ReactComponent as CalendarIcon } from "../../assets/svg/calendar-icon.svg";
import { ReactComponent as KeyIcon } from "../../assets/svg/key-icon.svg";
import { ReactComponent as ResultIcon } from "../../assets/svg/result-icon.svg";
import { ReactComponent as LeftIcon } from "../../assets/svg/arrow-left.svg";
import { ReactComponent as DoubleLeftIcon } from "../../assets/svg/double-arrow-left.svg";
import { ReactComponent as RightIcon } from "../../assets/svg/arrow-right.svg";
import { ReactComponent as DoubleRightIcon } from "../../assets/svg/double-arrow-right.svg";
import theme from "../../shared/theme";
import IconButton from "../IconButton";
import { useTranslation } from "react-i18next";
import { useTable, usePagination } from "react-table";
import { useSearch } from "../../contexts/SearchContext";
import { api321go } from "../../api/api321go";

const HistoryTable = ({ columns, data }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { setLoadingMap, setSearchTerms, setCountriesResult } = useSearch();

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 8 },
    },
    usePagination
  );

  const loadMap = async (token) => {
    if (token) {
      setLoadingMap(true);
      const { data } = await api321go.get(`/search?query_hash=${token}`);
      const { available, restricted, unavailable } = data;
      const searchKey = data.query_hash;
      setSearchTerms({ ...data, time: new Date(), searchKey });
      setCountriesResult({ available, restricted, unavailable });
      setTimeout(() => { setLoadingMap(false) }, 500);

      history.push(`/search/${searchKey}`);
    } else {
      console.log("nada");
    }
  };

  const cellRender = (cell, index) => {
    switch (cell.column.id) {
      case "date":
        return (
          <Td key={cell.column.id + index}>
            <Flex alignItems="center" justifyContent="flex-start">
              <CalendarIcon width="16" stroke={theme.colors.blue.default} />
              {cell.value}
            </Flex>
          </Td>
        );

      case "strategy":
        return (
          <Td key={cell.column.id + index}>
            <Flex alignItems="center" justifyContent="center">
              <KeyIcon width="16" stroke={theme.colors.blue.default} />
              <p>
                {cell.value.keyword01}
                <LogicType backgroundColor="#F14668">
                  {cell.value.boolean01}
                </LogicType>
                {cell.value.keyword02}
                <LogicType backgroundColor="#F2B349">
                  {cell.value.boolean02}
                </LogicType>
                {cell.value.keyword03}
              </p>
            </Flex>
          </Td>
        );

      case "id":
        return (
          <Td key={cell.column.id + index}>
            <Flex justifyContent="flex-end">
              <Button
                icon={ResultIcon}
                label={t("lbl_see_results")}
                onClick={() => loadMap(cell.row.values.strategy.query_hash)}
              />
            </Flex>
          </Td>
        );
      default:
        break;
    }
  };

  return (
    <Flex width="100%" flexDirection="column" justifyContent="space-between">
      <ScrollAreaRoot type="scroll">
        <ScrollAreaViewport>
          <Table {...getTableProps()}>
            <Thead>
              {headerGroups.map((headerGroup, index) => (
                <Tr key={index} {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column, index) => (
                    <Th key={index} {...column.getHeaderProps()}>
                      {column.render("Header")}
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>
            <Tbody {...getTableBodyProps()}>
              {page.map((row, index) => {
                prepareRow(row);
                return (
                  <Tr key={index} {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return cellRender(cell, index);
                    })}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </ScrollAreaViewport>

        <ScrollAreaScrollbar orientation="horizontal">
          <ScrollAreaThumb />
        </ScrollAreaScrollbar>
      </ScrollAreaRoot>

      <PaginationContainer>
        <IconButton
          size="large"
          icon={DoubleLeftIcon}
          onClick={() => gotoPage(0)}
          disabled={!canPreviousPage}
        />
        <IconButton
          size="large"
          icon={LeftIcon}
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
        />
        <IconButton
          size="large"
          icon={RightIcon}
          onClick={() => nextPage()}
          disabled={!canNextPage}
        />
        <IconButton
          size="large"
          icon={DoubleRightIcon}
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}
        />
      </PaginationContainer>
    </Flex>
  );
};

export default HistoryTable;