import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Section, Container, Row, RowContainer, Col, BlogListaContent, DisplayTags, BlogTitle, BlogMeta, BlogImage, OtherPostsGrid, DestaqueContent, DestaqueImage, Separator, InputWrapper, Input, SearchIcon } from './style';
import BlogInfos from '../../api/posts';
import Separate from '../../assets/svg/blog/Rectangle 36.png';
import Search from '../../assets/svg/blog/Icons (1).png';
import { useTranslation } from 'react-i18next';

const Blog = () => { 
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState('');

  const blogPosts = BlogInfos();

  const filteredPosts = blogPosts.filter(post =>
    post.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
    post.tags.some(tag => tag.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  const destaquePost = filteredPosts[0];
  const otherPosts = filteredPosts.slice(1);

  return (
    <Section className="blog-lista">
      <Container>
        <Row>
          <RowContainer>
            <InputWrapper>
              <Input
                type="text"
                placeholder="Pesquisando..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="form-control"
              />
              <SearchIcon src={Search} alt="Search Icon" />
            </InputWrapper>
            <Separator src={Separate} alt={Separate} className="mr-3"></Separator>
          </RowContainer>
        </Row>
        <Row>
          <Col lg={12}>
            {destaquePost && (
              <>
                <DestaqueImage src={destaquePost.image} alt={destaquePost.title} />
                <DestaqueContent>
                  <BlogTitle>
                    <Link to={`/blog/${0}`}>{destaquePost.title}</Link>
                  </BlogTitle>
                  <DisplayTags>
                    {destaquePost.tags.map((tag, tagIndex) => (
                      <span key={tagIndex}>{tag}</span>
                    ))}
                  </DisplayTags>
                  <BlogMeta>
                  {t('author')}: {destaquePost.author}, {destaquePost.date}
                  <br />
                  {t('tech_revisor')}: {destaquePost.techRevisor}
                  <br />
                  {t('portuguese_revisor')}: {destaquePost.PortugueseRevisor}
                  </BlogMeta>
                </DestaqueContent>
              </>
            )}
          </Col>
        </Row>
        <OtherPostsGrid>
          {otherPosts.map((post, index) => (
            <Col key={index} className="blog-lista-content">
              <BlogImage src={post.image} alt={post.title} />
              <BlogListaContent>
                <BlogTitle>
                  <Link to={`/blog/${index + 1}`}>{post.title}</Link>
                </BlogTitle>
                <DisplayTags>
                  {post.tags.map((tag, tagIndex) => (
                    <span key={tagIndex}>{tag}</span>
                  ))}
                </DisplayTags>
             <BlogMeta>
              {t('author')}: {post.author}, {post.date}
              <br />
              {t('tech_revisor')}: {post.techRevisor}
              <br />
              {t('portuguese_revisor')}: {post.PortugueseRevisor}
            </BlogMeta>
              </BlogListaContent>
            </Col>
          ))}
        </OtherPostsGrid>
      </Container>
    </Section>
  );
};

export default Blog;