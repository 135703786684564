import styled from "styled-components";

export const FilterContainer = styled.div`
    margin-bottom: 20px;
    box-shadow: rgba(0, 0, 0, 0.20) 4px 6px 8px;
    height: 100%;
    padding: 20px;
    border-radius: 8px;
    @media(max-width: 480px){
        width: 70%;
    }
`;

export const FilterInput = styled.input`
  margin-right: 16px;
  border-radius: 8px;
  border: 1.8px solid #000043; 
  padding-left: 8px;
  outline: none; 
  box-shadow: none; 
`;

export const FilterButton = styled.button`
    background-color: #000043;
    border-radius: 8px;
    color: #fff;
    border: none;
    padding: 4px;
    cursor: pointer;
`;

export const ContainerPrice = styled.div`
    display: flex;

    @media(max-width: 550px){
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px
    }
`
export const CheckboxContainer = styled.div`
    display: flex;
    align-items: center;
    margin: 5px 0;
    position: relative;
`;

export const Checkbox = styled.input.attrs({ type: 'checkbox' })`
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
`;

export const Label = styled.label`
    position: relative;
    padding-left: 30px;
    cursor: pointer;
    font-size: 16px;
    user-select: none;
    &:before {
        content: "";
        position: absolute;
        left: 0;
        width: 16px;
        height: 16px;
        border: 1px solid #000043;
        border-radius: 4px;
        margin-top: 4px;
        background-color: white; 
    }
    &:after {
        content: "";
        position: absolute;
        left: 3px; 
        top: 7px;
        width: 12px;
        height: 12px; 
        border-radius: 3px;
        background-color: #C6C6C6;
    }
    ${Checkbox}:checked ~ & {
        &:after {
            background-color: #5EE9EC; 
        }
    }
`;

export const TextContainer = styled.div`
    font-weight: bold;
    color: #000043;
    border-top: 1px solid #C6C6C6;
    padding-top: 20px;
    margin-top: 20px;
`;

export const PriceContainer = styled.div`
    font-weight: bold;
    color: #000043;
    margin-bottom: 16px;
`

export const StudyAreaContainer = styled.div`

`

export const StudyTypeContainer = styled.div`
    border-bottom: 1px solid #C6C6C6;
`

export const TooltipTrigger = styled.div`
width: 15px;
height: 15px;
background-color: #fff;
border: 2px solid #0000ff;
border-radius: 50%;
color: #0000ff;
cursor: pointer;
display: flex;
align-items: center;
justify-content: center;
font-size: 14px;
position: relative;
margin-left: 4px;

&:hover::after {
  content: attr(title);
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #0000ff;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  white-space: nowrap;
}
`;