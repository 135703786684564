import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import ScrollToTop from "../components/ScrollToTop";
import Home from "../pages/Home";
import Login from "../pages/Login";
import Signup from "../pages/Signup";
import ForgotPassword from "../pages/ForgotPassword";
import { Flex } from "../shared/style";
import NewPassword from "../pages/NewPassword";
import Dashboard from "../pages/Dashboard";
import Search from "../pages/Search";
import OurPlans from "../pages/OurPlans";
import RequireAuth from "./RequiredAuth";
import { AuthProvider } from "../contexts/AuthContext";
import { SearchProvider } from "../contexts/SearchContext";
import { FilterProvider } from "../contexts/SearchFilterContext";
import PlanDetail from "../pages/PlanDetail";
import ErrPage from "../pages/ErrPage";
import Download from "../pages/Download";
import ResultMap from "../pages/ResultMap";
import ContactUs from "../pages/ContactUs";
import Support from "../pages/Support";
import Blog from "../pages/Blog";
import BlogPost from "../components/Blog/BlogPost";
import AboutUs from "../pages/AboutUs";
import Commerce from "../pages/Commerce";
import CommercePayment from "../pages/CommercePayment";
import OurServices from "../pages/Services";
import ServicePayment from "../pages/ServicePayment";
import Teste from "../pages/Teste";

const TempNotFound = () => {
  return (
    <Flex style={{ height: "calc(100vh - 64px)" }}>
      <p>Temporary Not Found Page Component</p>
    </Flex>
  );
};

const AppRoutes = () => {
  return (
    <Router>
      <AuthProvider>
        <SearchProvider>
          <FilterProvider>
            <ScrollToTop />
            <Header />
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/teste" component={Teste} />
              <Route path="/home" component={Home} />
              <Route path="/about-us" component={AboutUs} />
              <Route path="/ecommerce" component={Commerce} />
              <Route path="/ecommerce/payment" component={CommercePayment} />
              <Route path="/our_services" component={OurServices} />
              <Route path="/service/payment" component={ServicePayment} />
              <Route path="/signup" component={Signup} />
              <Route path="/login" component={Login} />
              <Route path="/forgot_password" component={ForgotPassword} />
              <Route path="/new_password/:token" component={NewPassword} />
              <Route
                path="/dashboard"
                component={() => (
                  <RequireAuth>
                    <Dashboard />
                  </RequireAuth>
                )}
              />
              <Route
                path="/search"
                component={() => (
                  <RequireAuth>
                    <Search />
                  </RequireAuth>
                )}
              />
              <Route
                path="/search/:token"
                component={() => (
                  <RequireAuth>
                    <ResultMap />
                  </RequireAuth>
                )}
              />
              <Route
                path="/subscription"
                component={() => (
                  <RequireAuth>
                    <OurPlans />
                  </RequireAuth>
                )}
              />
              <Route
                path="/support"
                component={() => (
                  <RequireAuth>
                    <Support />
                  </RequireAuth>
                )}
              />
              <Route
                path="/search/:token/download"
                component={Download}
              />
              <Route path="/our_plans" component={OurPlans} />
              <Route path="/blog" component={Blog} />
              <Route path="/blog/:postId" component={BlogPost} />
              <Route path="/plan_detail" component={PlanDetail} />
              <Route path="/contact_us" component={ContactUs} />
              <Route path="/err_page" component={ErrPage} />
              <Route component={TempNotFound} />
            </Switch>
            <Footer />
          </FilterProvider>
        </SearchProvider>
      </AuthProvider>
    </Router>
  );
};

export default AppRoutes;