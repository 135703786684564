import React, { useState, useRef, useEffect } from "react";
import { Grid, Col, Row } from "react-flexbox-grid";
import { useTranslation } from "react-i18next";
import {
  TopContainer,
  RowLine,
  SliderContainer,
  GridWrapper,
  RowSelect,
  ToastRoot,
  ToastViewport,
  GridContent,
  BackBtn,
  PricePlan,
  HeadContainer,
  Image
} from "../ServicePayment/style";
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";
import { Link } from "react-router-dom";
import ArrowGoBg from "../../assets/svg/arrow-go-bg.svg";
import SeparateIcon from "../../assets/svg/separate-icon-service.svg"
import ModalContent from "../../components/ModalContent";
import { ImageSeparate } from "../ServicePayment/style";
import icon from "../../assets/svg/example-photo-commerce.svg";
import styled from "styled-components";
import validateEnvironment from "../../shared/helpers/validateEnvironment";
import { usePlan } from "../../hooks/usePlan";
import { api321go } from "../../api/api321go";
import * as yup from "yup";
import { Select } from "./Select";
import * as Toast from '@radix-ui/react-toast';
import { TicketForm } from "./TicketForm";
import { initMercadoPago, Payment, StatusScreen } from "@mercadopago/sdk-react";
import { ArrowLeft } from "react-feather";
import PlanDetail from "../PlanDetail";
import Button from "../../components/Button";

var delayTimer;

const NameProduct = styled.h1`
    color: #05036f;
    font-size: 24px;
    gap: 24px;
`
const ContainerDetails = styled.div`
    display: flex;
    flex-direction: column;
    width: 90%;
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    padding-right: 8px;
    padding-left: 8px;

`
const ContainerDetailsImage = styled.div`
    margin-top: 24px;
    gap: 16px;
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    padding-right: 8px;
    padding-left: 8px;
`

const Container = styled.div`
   display: flex;
    flex-direction: column;
    align-items: center;
`
const ContainerList = styled.div`
   display: flex;
   flex-direction: column;
`
const UlList = styled.ul`
  display: flex;
  list-style: none;
  color: rgb(0, 182, 186);
  font-weight: bold;
  flex-wrap: wrap; 
`;
const ContainerServices = styled.div`
display: flex;
    width: 100%;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    margin-top: 16px;
    align-content: space-between;
    align-items: center;
  @media(max-width: 800px){
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`
const BarraVertical = styled.span`
  margin: 0 5px; 
`;
const ContainerInput = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

const ServicePayment = () => {
  const publicKey = validateEnvironment({
    dev: process.env.REACT_APP_MERCADO_PAGO_PUBIC_KEY_DEV,
    prod: process.env.REACT_APP_MERCADO_PAGO_PUBIC_KEY_PROD,
  });

  initMercadoPago(`${publicKey}`, { locale: "pt-BR" });

  const formRef = useRef(null);
  const timerRef = useRef(0);
  const selectedProduct = localStorage.getItem("selectedProduct");
  const productDetails = JSON.parse(selectedProduct);

  const [payment, setPayment] = useState(null);
  const [loadingTicket, setLoafingTicket] = useState(false);
  const [introModalIsOpen, setIntroModalIsOpen] = useState(true);
  const [userData, setUserData] = useState({
    name: "",
    type_person: "fisica",
    cnpj_cpf: "",
    address: "",
    num_address: "",
    neighborhood: "",
    city: "",
    state: "",
    zipcode: "",
    email: "",
    price: "2.50",
    plan_id: "1",
  });
  const [openPaymentStatus, setOpenPaymentStatus] = useState(false);
  const paymentMethods = [
    {
      label: "Boleto",
      value: 1,
    },
    {
      label: "Cartão de crédito",
      value: 2,
    },
  ];
  const [paymentMethod, setPaymentMethod] = useState(paymentMethods[0]);
  const { createPayment, createPreference, preference } = usePlan();
  const successUrl = validateEnvironment({
    dev: process.env.REACT_APP_MERCADO_PAGO_SUCCESS_URL_DEV,
    prod: process.env.REACT_APP_MERCADO_PAGO_SUCCESS_URL_PROD,
  });
  const failureUrl = validateEnvironment({
    dev: process.env.REACT_APP_MERCADO_PAGO_FAILURE_URL_DEV,
    prod: process.env.REACT_APP_MERCADO_PAGO_FAILURE_URL_PROD,
  });

  const initialization = preference && {
    amount: productDetails.unit_price,
    preferenceId: preference.id,
  };

  useEffect(() => {
    createPreference({
      id: productDetails.id,
      title: productDetails.name,
      unit_price: productDetails.unit_price,
      success_url: successUrl,
      failure_url: failureUrl,
    });
  }, []);

  useEffect(() => {
    return () => clearTimeout(timerRef.current);
  }, []);

  const onPaymentStatus = () => {
    setOpenPaymentStatus(false);
    window.clearTimeout(timerRef.current);
    timerRef.current = window.setTimeout(() => {
      setOpenPaymentStatus(true);
    }, 100);
  }

  const handleCloseIntroModal = () => {
    setIntroModalIsOpen(!introModalIsOpen);
  };

  const { t } = useTranslation();
  const [currentSlide, setCurrentSlide] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [sliderRef, instanceRef] = useKeenSlider({
    slides: {
      perView: 3,
      spacing: 12,
    },
    breakpoints: {
      "(max-width: 768px)": {
        slides: {
          perView: 2,
          spacing: 12,
        },
      },
      "(max-width: 640px)": {
        slides: {
          perView: 1,
          spacing: 12,
        },
      },
    },
    initial: 0,
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
    created() {
      setLoaded(true);
    },
  });

  const validateFieldsToSubmit = async (data) => {
    try {
      formRef.current.setErrors({});

      const signupSchema = yup.object().shape({
        name: yup.string().required(t("form_error_full_name_required")),
        cnpj_cpf: yup.string().required(t("lbl_CPF_required")),
        zipcode: yup.string().required(t("lbl_CEP_must_contain")),
        email: yup.string().email(t("form_error_email_invalid")).required(t("form_error_email_required")),
        // password: yup
        //     .string()
        //     .matches(/.*?[A-Z]/, t("form_error_password_invalid"))
        //     .matches(/.*?[a-z]/, t("form_error_password_invalid"))
        //     .matches(/.*?[0-9]/, t("form_error_password_invalid"))
        //     .matches(/.*?[#?!@$%^&*-]/, t("form_error_password_invalid"))
        //     .min(8, t("form_error_password_invalid"))
        //     .required(t("form_error_password_required")),
        // confirm_password: yup
        //     .string()
        //     .oneOf(
        //         [yup.ref("password"), null],
        //         t("form_error_password_not_match")
        //     ),
      });

      await signupSchema.validate(data, {
        abortEarly: false,
      });

      setLoafingTicket(true);
      try {
        const { data } = await api321go.post(
          `/plan/payment`,
          { ...userData, plan_id: "1", price: selectedProduct.price.replace(",", ".").replace("R$", "").trim() },
          { responseType: "blob" },
        );
        const url = window.URL.createObjectURL(new Blob([data], { type: "application/pdf" }));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `boleto-321go.pdf`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setLoafingTicket(false);
      } catch (error) {
        console.log(error);
        setLoafingTicket(false);
      }
      // window.open(fileURL);

      // await handleSignup(data);
    } catch (error) {
      const validationErrors = {};
      if (error instanceof yup.ValidationError) {
        error.inner.forEach((err) => {
          validationErrors[err.path] = err.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  };

  const handleChangePaymentMethod = (value) => {
    setPaymentMethod(value);
  };

  const onSubmitCardPayment = async ({ formData }) => {
    const response = await createPayment({ payment_data: formData });

    if (response) {
      setPayment(response);
      onPaymentStatus();
    }


    // if (response && (response.status === "approved" || response.status === "in_process")) {
    //   window.location.href = preference.back_urls.success;
    // }

    // window.location.href = preference.back_urls.failure;
  };


  return (
    <div>
    <HeadContainer
          fluid
          style={{
            background: `#05036F url(${ArrowGoBg})`,
            backgroundClip: "cover",
            backgroundRepeat: "no-repeat",
          }}
        >
          <Grid>
            <Row>
              <Col xs={12}>
                <Link to="/our_services">
                  <BackBtn>
                    <ArrowLeft strokeWidth={3} size={20} />
                    {t("lbl_back")}
                  </BackBtn>
                </Link>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <PricePlan>
                  <h3>{t(productDetails.name)}</h3>
                  <h1>{t(productDetails.price)}</h1>
                </PricePlan>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <RowLine />
              </Col>
            </Row>
          </Grid>
        </HeadContainer>
      <Container>
        <GridWrapper>
          <SliderContainer>
            <ContainerDetailsImage>
              <NameProduct>
                Detalhes do Serviço
              </NameProduct>
             <UlList>
             {productDetails.tags.slice(0, 4).map((tag, index) => (
                  <React.Fragment key={index}>
                    {index > 0 && <BarraVertical>|</BarraVertical>}
                    <li>{t(tag)}</li>
                  </React.Fragment>
                ))}
                {productDetails.tags.length > 4 && (
                  <li>+ {productDetails.tags.length - 4} {t("more")}</li>
                )}
              </UlList>
              <Image src={icon} alt={icon}/>
            </ContainerDetailsImage>
          </SliderContainer>
          <div>{t(productDetails.description)}</div>
        </GridWrapper>
        <ImageSeparate src={SeparateIcon} alt="Separate Icon" />
      </Container>

      <ContainerServices>
              <div>Informe seus dados para ter acesso ao especialista</div>
              <ContainerInput>
              <label>
                  Nome social:
              </label>
              <input type="text" name="nomeSocial" />
          </ContainerInput>
          <ContainerInput>
              <label>
                  E-mail
              </label>
              <input type="email" name="email" />
          </ContainerInput>
          <ContainerInput>
              <label>
                  Qual sua dúvida?
              </label>
              <input type="text" name="duvida" />
          </ContainerInput>
          <Button label='Acionar contato'/>
      </ContainerServices>
    </div>
  );
};

export default ServicePayment;
