import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import ButtonFilter from "../../../assets/svg/button-icon-service.svg"
import Tip from "../../../assets/svg/tip-icon.svg"
import { FilterContainer, ContainerPrice, PriceContainer, FilterInput, Checkbox,  
Label, CheckboxContainer, FilterButton, StudyAreaContainer, StudyTypeContainer, TextContainer, TooltipTrigger } from "./style";
import { useFilter } from '../../../contexts/SearchFilterContext';
import { useState } from "react";

const Filter = ({ onFilter }) => {
  const { t } = useTranslation();
  const [selectedStudyType, setSelectedStudyType] = useState('studyTypeTodos');
  const [selectedStudyArea, setSelectedStudyArea] = useState('studyAreaTodos');
  const { country, setCountry, name, setName, minPrice, setMinPrice, maxPrice, setMaxPrice, studyArea, setStudyArea, studyType, setStudyType, filters, setFilters } = useFilter(); // Use o hook useFilter para obter o estado e as funções de configuração
  const allStudyAreas = ["lbl_area_all", "lbl_services_needs", "lbl_area_quimicandmetalurgic", "lbl_area_texteisandpaper", "lbl_area_enginner", "lbl_area_fisic", "lbl_area_eletric", "lbl_area_newdesenvolviment", ""];
  const allStudyTypes = ["lbl_area_all", "lbl_filter_search", "lbl_filter_technique", "lbl_filter_tendecy", "lbl_filter_concorrency", ""];

  useEffect(() => {
    if (selectedStudyArea === "studyAreaTodos") {
      setStudyArea(allStudyAreas);
    } else if (selectedStudyArea !== 'studyAreaTodos') {
      setStudyArea([selectedStudyArea]);
    }
  
    if (selectedStudyType === "studyTypeTodos") {
      setStudyType(allStudyTypes);
    } else if (selectedStudyType !== 'studyTypeTodos') {
      setStudyType([selectedStudyType]);
    }
  }, [selectedStudyArea, selectedStudyType]);
  
  const handleFilter = (e, setFunction, group) => {
    if (group === "studyArea") {
      setSelectedStudyArea(e.target.name);
    } else if (group === "studyType") {
      setSelectedStudyType(e.target.name);
    }
  };
  
  const applyFilters = () => {
    setFilters({ country, name, minPrice, maxPrice, studyArea, studyType });
  };
  
  useEffect(() => {
    onFilter(filters);
  }, [filters]);

  return (
    <FilterContainer>
      <PriceContainer>{t('lbl_price')}</PriceContainer>
      <ContainerPrice>
        <FilterInput
          type="number"
          placeholder={t('lbl_minimumvalue')}
          value={minPrice}
          onChange={(e) => setMinPrice(e.target.value)}
        />
        <FilterInput
          type="number"
          placeholder={t('lbl_maximumvalue')}
          value={maxPrice}
          onChange={(e) => setMaxPrice(e.target.value)}
        />
        <FilterButton onClick={applyFilters}>
          <img src={ButtonFilter} alt="Filtrar" />
        </FilterButton>
      </ContainerPrice>
      <StudyAreaContainer>
        <TextContainer>{t("lbl_studyarea")}</TextContainer>
      <CheckboxContainer>
        <Checkbox id="studyAreaTodos" name="studyAreaTodos" checked={selectedStudyArea === 'studyAreaTodos'} onChange={(e) => handleFilter(e, setStudyArea, "studyArea")} />
        <Label htmlFor="studyAreaTodos">{t("lbl_area_all")}</Label>
      </CheckboxContainer>
        <CheckboxContainer>
        <Checkbox id="lbl_services_needs" name="lbl_services_needs" checked={selectedStudyArea === 'lbl_services_needs'} onChange={(e) => handleFilter(e, setStudyArea, "studyArea")} />
        <Label htmlFor="lbl_services_needs">{t("lbl_services_needs")}</Label>
        <TooltipTrigger title={t("lbl_tooltip_needs")}>?</TooltipTrigger>
      </CheckboxContainer>
      <CheckboxContainer>
        <Checkbox id="lbl_services_operations" name="lbl_services_operations" checked={selectedStudyArea === 'lbl_services_operations'} onChange={(e) => handleFilter(e, setStudyArea, "studyArea")} />
        <Label htmlFor="lbl_services_operations">{t("lbl_services_operations")}</Label>
        <TooltipTrigger title={t("lbl_tooltip_operations")}>?</TooltipTrigger>
      </CheckboxContainer>
      <CheckboxContainer>
        <Checkbox id="lbl_area_quimicandmetalurgic" name="lbl_area_quimicandmetalurgic" checked={selectedStudyArea === 'lbl_area_quimicandmetalurgic'} onChange={(e) => handleFilter(e, setStudyArea, "studyArea")} />
        <Label htmlFor="lbl_area_quimicandmetalurgic">{t("lbl_area_quimicandmetalurgic")}</Label>
        <TooltipTrigger title={t("lbl_tooltipl_quimicandmetalurgic")}>?</TooltipTrigger>
      </CheckboxContainer>
      <CheckboxContainer>
        <Checkbox id="lbl_area_texteisandpaper" name="lbl_area_texteisandpaper" checked={selectedStudyArea === 'lbl_area_texteisandpaper'} onChange={(e) => handleFilter(e, setStudyArea, "studyArea")} />
        <Label htmlFor="lbl_area_texteisandpaper">{t("lbl_area_texteisandpaper")}</Label>
        <TooltipTrigger title={t("lbl_tooltip_texteisandpaper")}>?</TooltipTrigger>
      </CheckboxContainer>
      <CheckboxContainer>
        <Checkbox id="lbl_services_constructions" name="lbl_services_constructions" checked={selectedStudyArea === 'lbl_services_constructions'} onChange={(e) => handleFilter(e, setStudyArea, "studyArea")} />
        <Label htmlFor="lbl_services_constructions">{t("lbl_services_constructions")}</Label>
        <TooltipTrigger title={t("lbl_tooltip_constructions")}>?</TooltipTrigger>
      </CheckboxContainer>
      <CheckboxContainer>
        <Checkbox id="lbl_area_enginner_mechanic" name="lbl_area_enginner_mechanic" checked={selectedStudyArea === 'lbl_area_enginner_mechanic'} onChange={(e) => handleFilter(e, setStudyArea, "studyArea")} />
        <Label htmlFor="lbl_area_enginner_mechanic">{t("lbl_area_enginner_mechanic")}</Label>
        <TooltipTrigger title={t("lbl_tooltip_enginner_mechanic")}>?</TooltipTrigger>
      </CheckboxContainer>
      <CheckboxContainer>
        <Checkbox id="lbl_area_fisic" name="lbl_area_fisic" checked={selectedStudyArea === 'lbl_area_fisic'} onChange={(e) => handleFilter(e, setStudyArea, "studyArea")} />
        <Label htmlFor="lbl_area_fisic">{t("lbl_area_fisic")}</Label>
        <TooltipTrigger title={t("lbl_toltip_fisic")}>?</TooltipTrigger>
      </CheckboxContainer>
      <CheckboxContainer>
        <Checkbox id="lbl_area_eletric" name="lbl_area_eletric" checked={selectedStudyArea === 'lbl_area_eletric'} onChange={(e) => handleFilter(e, setStudyArea, "studyArea")} />
        <Label htmlFor="lbl_area_eletric">{t("lbl_area_eletric")}</Label>
        <TooltipTrigger title={t("lbl_tooltip_eletric")}>?</TooltipTrigger>
      </CheckboxContainer>
      </StudyAreaContainer>
      <StudyTypeContainer>
        <TextContainer>{t("lbl_studytype")}</TextContainer>
        <CheckboxContainer>
        <Checkbox id="studyTypeTodos" name="studyTypeTodos" checked={selectedStudyType === 'studyTypeTodos'} onChange={(e) => handleFilter(e, setStudyType, "studyType")} />
        <Label htmlFor="studyTypeTodos">{t("lbl_area_all")}</Label>
      </CheckboxContainer>
      <CheckboxContainer>
        <Checkbox id="lbl_filter_search" name="lbl_filter_search" checked={selectedStudyType === 'lbl_filter_search'} onChange={(e) => handleFilter(e, setStudyType, "studyType")} />
        <Label htmlFor="lbl_filter_search">{t("lbl_filter_search")}</Label>
      </CheckboxContainer>
      <CheckboxContainer>
        <Checkbox id="lbl_filter_technique" name="lbl_filter_technique" checked={selectedStudyType === 'lbl_filter_technique'} onChange={(e) => handleFilter(e, setStudyType, "studyType")} />
        <Label htmlFor="lbl_filter_technique">{t("lbl_filter_technique")}</Label>
      </CheckboxContainer>
      <CheckboxContainer>
        <Checkbox id="lbl_filter_tendecy" name="lbl_filter_tendecy" checked={selectedStudyType === 'lbl_filter_tendecy'} onChange={(e) => handleFilter(e, setStudyType, "studyType")} />
        <Label htmlFor="lbl_filter_tendecy">{t("lbl_filter_tendecy")}</Label>
      </CheckboxContainer>
      <CheckboxContainer>
        <Checkbox id="lbl_filter_concorrency" name="lbl_filter_concorrency" checked={selectedStudyType === 'lbl_filter_concorrency'} onChange={(e) => handleFilter(e, setStudyType, "studyType")} />
        <Label htmlFor="lbl_filter_concorrency">{t("lbl_filter_concorrency")}</Label>
      </CheckboxContainer>
      </StudyTypeContainer>
    </FilterContainer>
  );
};

export default Filter;
