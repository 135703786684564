import React, { useRef, useState } from "react";
import { Col, Grid, Row } from "react-flexbox-grid";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Button from "../../components/Button";
import Feedback from "../../components/Feedback";
import Input from "../../components/Input";
import Panel from "../../components/Panel";
import { ContentContainer, RowWrapper } from "../../shared/style";
import theme from "../../shared/theme";
import { ForgotPasswordRow } from "./style";
import { Form } from "@unform/web";
import * as yup from "yup";
import { useAuth } from "../../contexts/AuthContext";

const ForgotPassword = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [feedback, setFeedback] = useState(null);
  const formRef = useRef(null);
  const { handleForgotPassword } = useAuth();

  const validateFieldsToSubmit = async (data) => {
    try {
      formRef.current.setErrors({});

      const loginScheme = yup.object().shape({
        email: yup
          .string()
          .email(t("form_error_email_invalid"))
          .required(t("form_error_email_required")),
      });

      await loginScheme.validate(data, {
        abortEarly: false,
      });

      forgotPassword(data);
    } catch (error) {
      const validationErrors = {};
      if (error instanceof yup.ValidationError) {
        error.inner.forEach((err) => {
          validationErrors[err.path] = err.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  };

  const forgotPassword = async (data) => {
    try {
      await handleForgotPassword(data.email);

      setFeedback({
        title: t("fb_success_forgot_password_title"),
        explain: t("fb_success_forgot_password_body"),
        success: true,
      });
    } catch (error) {
      console.log(error);
    }
  };

  if (feedback) {
    return <Feedback feedback={feedback} />;
  }

  return (
    <ContentContainer>
      <Grid>
        <RowWrapper middle="md">
          <Col lg={8}>
            <h3>{t("page_forgot_password_forgot_pass")}</h3>
          </Col>
          <Col lg={4}>
            <Panel>
              <Grid>
                <Col>
                  <ForgotPasswordRow>
                    <h1 style={{ color: theme.colors.blue.default }}>
                      {t("page_forgot_password_card_title")}
                    </h1>
                  </ForgotPasswordRow>
                  <Form ref={formRef} onSubmit={validateFieldsToSubmit}>
                    <ForgotPasswordRow>
                      <Input
                        placeholder={t("ph_login_email")}
                        label={t("lbl_email")}
                        name="email"
                      />
                    </ForgotPasswordRow>

                    <Row between="lg">
                      <Col lg={6} style={{ paddingLeft: 0 }}>
                        <Button
                          type="submit"
                          width="100%"
                          size="large"
                          label={t("lbl_send")}
                        />
                      </Col>
                      <Col lg={6} style={{ paddingRight: 0 }}>
                        <Button
                          buttonStyle="outlined"
                          width="100%"
                          size="large"
                          label={t("lbl_cancel")}
                          onClick={() => history.push("/login")}
                        />
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Grid>
            </Panel>
          </Col>
        </RowWrapper>
      </Grid>
    </ContentContainer>
  );
};

export default ForgotPassword;