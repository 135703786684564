import { useState } from "react";
import { createContext, useContextSelector } from "use-context-selector";

const FilterContext = createContext({});

export const FilterProvider = ({ children }) => {
    const [country, setCountry] = useState("");
    const [name, setName] = useState("");
    const [minPrice, setMinPrice] = useState("");
    const [maxPrice, setMaxPrice] = useState("");
    const [studyArea, setStudyArea] = useState([]);
    const [studyType, setStudyType] = useState([]);
    const [filters, setFilters] = useState({ country, name, minPrice, maxPrice, studyArea, studyType });

    return (
        <FilterContext.Provider value={{
            country,
            setCountry,
            name,
            setName,
            minPrice,
            setMinPrice,
            maxPrice,
            setMaxPrice,
            studyArea,
            setStudyArea,
            studyType,
            setStudyType,
            filters,
            setFilters,
        }}>
            {children}
        </FilterContext.Provider>
    )
};

export function useFilter() {
    const country = useContextSelector(
        FilterContext, (action) => action.country
    );
    const setCountry = useContextSelector(
        FilterContext, (action) => action.setCountry
    );
    const name = useContextSelector(
        FilterContext, (action) => action.name
    );
    const setName = useContextSelector(
        FilterContext, (action) => action.setName
    );
    const minPrice = useContextSelector(
        FilterContext, (action) => action.minPrice
    );
    const setMinPrice = useContextSelector(
        FilterContext, (action) => action.setMinPrice
    );
    const maxPrice = useContextSelector(
        FilterContext, (action) => action.maxPrice
    );
    const setMaxPrice = useContextSelector(
        FilterContext, (action) => action.setMaxPrice
    );
    const studyArea = useContextSelector(
        FilterContext, (action) => action.studyArea
    );
    const setStudyArea = useContextSelector(
        FilterContext, (action) => action.setStudyArea
    );
    const studyType = useContextSelector(
        FilterContext, (action) => action.studyType
    );
    const setStudyType = useContextSelector(
        FilterContext, (action) => action.setStudyType
    );
    const filters = useContextSelector(
        FilterContext, (action) => action.filters
    );
    const setFilters = useContextSelector(
        FilterContext, (action) => action.setFilters
    );

    return {
        country,
        setCountry,
        name,
        setName,
        minPrice,
        setMinPrice,
        maxPrice,
        setMaxPrice,
        studyArea,
        setStudyArea,
        studyType,
        setStudyType,
        filters,
        setFilters,
    };
}