import React from 'react';
import styled from 'styled-components';
import { useFilter } from '../../../contexts/SearchFilterContext';
import { useTranslation } from "react-i18next";


const StyledInput = styled.input`
  width: 50%;

  padding: 15px;
  padding-right: 400px;
  font-size: 16px;
  border-radius: 5px;
  border: 2px solid #000043;
  margin-bottom: 24px;

  @media(max-width: 900px){
    width: 35%;
    padding-right: 300px;
  }
  @media(max-width: 550px){
    width: 40%;
    padding-right: 100px;
  }
`;

const FilterByName = () => {
  const { t } = useTranslation();
  const { name, setName, setFilters, filters } = useFilter();

  const handleFilterByName = (event) => {
    setName(event.target.value);
    setFilters({ ...filters, name: event.target.value });
  };

  return (
    <>
      <StyledInput
        type="text"
        value={name}
        onChange={handleFilterByName}
        placeholder={t("lbl_search_item")}
      />
     
    </>
  );
};

export default FilterByName;
