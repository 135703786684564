import { Form } from "@unform/web";
import React, { useEffect, useRef, useState } from "react";
import { Col, Grid, Row } from "react-flexbox-grid";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import Button from "../../components/Button";
import Feedback from "../../components/Feedback";
import Input from "../../components/Input";
import Panel from "../../components/Panel";
import { ContentContainer, RowWrapper } from "../../shared/style";
import theme from "../../shared/theme";
import { NewPasswordRow } from "./styles";
import * as yup from "yup";
import { useAuth } from "../../contexts/AuthContext";

const NewPassword = ({ match }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [feedback, setFeedback] = useState(null);
  const formRef = useRef(null);
  const { handleResetPassword } = useAuth();
  const { token } = useParams();

  const validateToken = async () => {
    // Aqui validamos se o token é válido no backend
    // const tokenIsValid = token == 123;
    // if (!tokenIsValid) {
    //   setFeedback({
    //     title: t("fb_failure_general_title"),
    //     explain: t("fb_failure_general_body"),
    //     success: false,
    //   });
    // }
  };

  const validateFieldsToSubmit = async (data) => {
    try {
      formRef.current.setErrors({});

      const signupSchema = yup.object().shape({
        password: yup
          .string()
          .matches(/.*?[A-Z]/, t("form_error_password_invalid"))
          .matches(/.*?[a-z]/, t("form_error_password_invalid"))
          .matches(/.*?[0-9]/, t("form_error_password_invalid"))
          .matches(/.*?[#?!@$%^&*-]/, t("form_error_password_invalid"))
          .min(8, t("form_error_password_invalid"))
          .required(t("form_error_password_required")),
        confirm_password: yup
          .string()
          .oneOf(
            [yup.ref("password"), null],
            t("form_error_password_not_match")
          ),
      });

      await signupSchema.validate(data, {
        abortEarly: false,
      });

      handleConfigureNewPassword(data);
    } catch (error) {
      const validationErrors = {};
      if (error instanceof yup.ValidationError) {
        error.inner.forEach((err) => {
          validationErrors[err.path] = err.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  };

  const handleConfigureNewPassword = async (data) => {
    try {
      await handleResetPassword(data.password, token);
      setFeedback({
        title: t("fb_success_new_password_title"),
        explain: t("fb_success_new_password_body"),
        success: true,
        feedbackAction: {
          action: () => history.push("/login"),
          label: t("fb_success_new_password_action"),
        },
      });
    } catch (error) {
      console.log(error);

      setFeedback({
        title: t("fb_failure_general_title"),
        explain: t("fb_failure_general_body"),
        success: false,
        feedbackAction: {
          action: () => setFeedback(null),
          label: t("lbl_back"),
        },
      });
    }
  };

  if (feedback) {
    return <Feedback feedback={feedback} />;
  }

  return (
    <ContentContainer>
      <Grid>
        <RowWrapper middle="md">
          <Col lg={8}>
            <h3>{t("page_forgot_password_forgot_pass")}</h3>
          </Col>
          <Col lg={4}>
            <Panel>
              <Grid>
                <Col>
                  <NewPasswordRow>
                    <h1 style={{ color: theme.colors.blue.default }}>
                      {t("page_new_password_provide_new")}
                    </h1>
                  </NewPasswordRow>
                  <Form ref={formRef} onSubmit={validateFieldsToSubmit}>
                    <NewPasswordRow>
                      <Input
                        type="password"
                        placeholder={t("ph_password")}
                        label={t("lbl_password")}
                        name="password"
                        tip={t("password_tooltip")}
                      />
                    </NewPasswordRow>

                    <NewPasswordRow>
                      <Input
                        type="password"
                        placeholder={t("ph_confirm_password")}
                        label={t("lbl_confirm_password")}
                        name="confirm_password"
                      />
                    </NewPasswordRow>

                    <Row between="lg">
                      <Col lg={6} style={{ paddingLeft: 0 }}>
                        <Button
                          type="submit"
                          width="100%"
                          size="large"
                          label={t("lbl_save")}
                        />
                      </Col>
                      <Col lg={6} style={{ paddingRight: 0 }}>
                        <Button
                          buttonStyle="outlined"
                          width="100%"
                          size="large"
                          label={t("lbl_cancel")}
                          onClick={() => history.push("/login")}
                        />
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Grid>
            </Panel>
          </Col>
        </RowWrapper>
      </Grid>
    </ContentContainer>
  );
};

export default NewPassword;